import { Injectable } from "@angular/core";
import {
  AngularFirestore,
  AngularFirestoreCollection,
} from "@angular/fire/firestore";
import { from, Observable } from "rxjs";
import { map } from "rxjs/operators";
import { Projet } from "../model/projet";

@Injectable({
  providedIn: "root",
})
export class ProjetService {
  COLLECTION = "Projet";
  private collection: AngularFirestoreCollection<Projet>;
  projets$: Observable<any>;

  constructor(private fire: AngularFirestore) {}

  getAll(): Observable<any> {
    this.collection = this.fire.collection(this.COLLECTION, (ref) =>
      ref.orderBy("titre", "asc")
    );

    this.projets$ = this.collection.snapshotChanges().pipe(
      map((actions) => {
        return actions.map((a) => {
          const data = a.payload.doc.data() as Projet;
          data.id = a.payload.doc.id;
          return { ...data };
        });
      })
    );
    return this.projets$;
  }

  getById(id: string): Observable<any> {
    this.projets$ = from(
      this.fire
        .collection(this.COLLECTION)
        .doc(id)
        .get()
        .toPromise()
        .then((doc) => {
          return doc.data();
        })
        .catch((error) => {
          console.log("Error getting document:", error);
        })
    );
    return this.projets$;
  }

  create(data: Projet): any {
    return new Promise<any>((resolve, reject) => {
      this.fire
        .collection(this.COLLECTION)
        .add(data)
        .then(
          (res) => {},
          (err) => reject(err)
        );
    });
  }

  update(data: Projet): any {
    this.fire
      .collection(this.COLLECTION)
      .doc(data.id)
      .set(Object.assign({}, data));
  }
}
