import { LocationStrategy, PathLocationStrategy } from "@angular/common";
import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from "@angular/core";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { BrowserModule } from "@angular/platform-browser";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { NgbCarouselModule, NgbModule } from "@ng-bootstrap/ng-bootstrap";
import { AnimateModule } from "@wizdm/animate";
import { TabModule } from "angular-tabs-component";
import { AboutComponent } from "./about/about.component";
import { AppLayoutComponent } from "./app-layout/app-layout.component";
import { AppRoutingModule } from "./app-routing.module";
import { AppComponent } from "./app.component";
import { CardComponent } from "./card/card.component";
import { CarousselComponent } from "./caroussel/caroussel.component";
import { ContactComponent } from "./contact/contact.component";
import { DropzoneDirective } from "./directives/dropzone.directive";
import { FooterComponent } from "./foot/footer.component";
import { HomeComponent } from "./home/home.component";
import { AppMenuComponent } from "./menu/app-menu.component";
import { SidebarComponent } from "./menu/sidebar/sidebar.component";
import { ToolbarComponent } from "./menu/toolbar/toolbar/toolbar.component";
import { PageNotFoundComponent } from "./page-not-found/page-not-found.component";
import { SafeHtmlPipe } from "./pipes/safe-html-pipe.pipe";
import { ProjectTabComponent } from "./project-tab/project-tab.component";
import { ProjectComponent } from "./project/project.component";
import { FirebaseModule } from "./shared/firebase/firebase.module";
import { MaterialModule } from "./shared/material/material.module";
import { SharedModule } from "./shared/shared.module";
import { UploadTaskComponent } from "./upload-task/upload-task.component";
import { UploaderComponent } from "./uploader/uploader.component";
import { PhaseAnneePipe } from "./_pipes/phase-annee.pipe";
import { SurfacePipe } from "./_pipes/surface.pipe";
import { FlatCarousselComponent } from "./flat-caroussel/flat-caroussel.component";
import { AdministrationComponent } from "./administration/administration.component";
import { MultilangueComponent } from "./multilangue/multilangue.component";
import { TranslatePipe } from "./_pipes/translate.pipe";
import { ChangeLangComponent } from "./change-lang/change-lang.component";
import { TroncateStringPipe } from "./_pipes/troncate-string.pipe";
import { CategoriesComponent } from "./categories/categories.component";
import { ProjectCategoryTabComponent } from './project-category-tab/project-category-tab.component';
import { CategoryIndexPipe } from './_pipes/category-index.pipe';
import { ProjectIndexPipe } from './_pipes/project-index.pipe';
import { CategoryIdPipe } from './_pipes/category-id.pipe';

@NgModule({
  declarations: [
    AppComponent,
    AppMenuComponent,
    FooterComponent,
    AboutComponent,
    SidebarComponent,
    ContactComponent,
    ProjectComponent,
    PageNotFoundComponent,
    HomeComponent,
    SafeHtmlPipe,
    CarousselComponent,
    ToolbarComponent,
    AppLayoutComponent,
    DropzoneDirective,
    UploaderComponent,
    UploadTaskComponent,
    CardComponent,
    ProjectTabComponent,
    SurfacePipe,
    PhaseAnneePipe,
    FlatCarousselComponent,
    AdministrationComponent,
    MultilangueComponent,
    TranslatePipe,
    ChangeLangComponent,
    TroncateStringPipe,
    CategoriesComponent,
    ProjectCategoryTabComponent,
    CategoryIndexPipe,
    ProjectIndexPipe,
    CategoryIdPipe,
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    NgbModule,
    MaterialModule,
    SharedModule,
    FormsModule,
    ReactiveFormsModule,
    AnimateModule,
    FirebaseModule,
    NgbCarouselModule,
    TabModule,
  ],
  providers: [{ provide: LocationStrategy, useClass: PathLocationStrategy }],
  bootstrap: [AppComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class AppModule {}
