import { NgModule } from "@angular/core";
import { Routes, RouterModule } from "@angular/router";
import { AdministrationComponent } from "./administration/administration.component";
import { ContactComponent } from "./contact/contact.component";
import { HomeComponent } from "./home/home.component";
import { PageNotFoundComponent } from "./page-not-found/page-not-found.component";
import { ProjectComponent } from "./project/project.component";
import { UploaderComponent } from "./uploader/uploader.component";

const routes: Routes = [
  {
    path: "home",
    component: HomeComponent,
  },
  {
    path: "contact",
    component: ContactComponent,
  },
  {
    path: "administration",
    component: AdministrationComponent,
  },
  {
    path: "project",
    component: ProjectComponent,
    data: { title: "Projects en cours" },
  },
  { path: "", redirectTo: "/home", pathMatch: "full" },
  { path: "**", component: PageNotFoundComponent },
];
@NgModule({
  imports: [
    RouterModule.forRoot(routes, { scrollPositionRestoration: "enabled" }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
