import { Pipe, PipeTransform } from "@angular/core";
import { Observable } from "rxjs";
import { MultilangueService } from "../service/multilangue.service";

@Pipe({
  name: "translate",
})
export class TranslatePipe implements PipeTransform {
  constructor(private db: MultilangueService) {}
  transform(code: string, page: string, langue: string): Observable<string> {
    let translate: Observable<string>;
    translate = this.db.getLibelleBy(page, code, langue);

    return translate;
  }
}
