import { AfterViewInit, Component, OnInit } from "@angular/core";
import { Observable } from "rxjs";
import { map } from "rxjs/operators";
import { ProjetService } from "../service/projet.service";
import {
  CategoryProject,
  CategoryProjetService,
} from "../services/category-projet.service";

@Component({
  selector: "app-home",
  templateUrl: "./home.component.html",
  styleUrls: ["./home.component.scss"],
})
export class HomeComponent implements OnInit {
  projet$: any;
  categories$: CategoryProject[];
  breakpoint: number;

  constructor(
    private db: ProjetService,
    private categoryService: CategoryProjetService
  ) {}

  ngOnInit() {
    this.projet$ = this.db.getAll();
    this.categoryService.getAll().subscribe((item) => {
      this.categories$ = item;
    });
    this.breakpoint = window.innerWidth <= 400 ? 1 : 3;
  }

  getCatIndex(idProjet) {
    const category = this.categories$.find((x) =>
      x.projet.filter((project) => (project = idProjet))
    );

    return this.categories$.findIndex((cat) => (cat = category));
  }

  getProjectIndex(idProjet) {
    const category: CategoryProject = this.categories$.find((x) =>
      x.projet.filter((project) => (project = idProjet))
    );

    return category.projet.findIndex((projet) => (projet = idProjet));
  }

  getCatId(idProjet) {
    const result: CategoryProject = this.categories$.find((x) =>
      x.projet.filter((project) => (project = idProjet))
    );

    return result.category.id;
  }

  onResize(event) {
    this.breakpoint = event.target.innerWidth <= 400 ? 1 : 3;
  }

  getProjet(projet: string) {
    return this.db.getById(projet);
  }
}
