import { Component, EventEmitter, OnInit, Output } from "@angular/core";
import { LangService } from "../service/lang.service";

@Component({
  selector: "app-change-lang",
  templateUrl: "./change-lang.component.html",
  styleUrls: ["./change-lang.component.scss"],
})
export class ChangeLangComponent implements OnInit {
  @Output()
  lang: EventEmitter<string> = new EventEmitter<string>();

  readonly languages = [
    { value: "fr", code: "fr", label: "Français" },
    { value: "gb", code: "en", label: "English" },
  ];

  public language = this.languages[0];

  constructor(private langService: LangService) {}

  selectLanguage(value: string) {
    this.language = this.languages.find((lang) => lang.value === value);
    this.langService.emitLang(this.language.code);
  }

  ngOnInit() {}
}
