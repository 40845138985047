import { Component, OnInit } from "@angular/core";
import { LangService } from "src/app/service/lang.service";

@Component({
  selector: "app-translator",
  templateUrl: "./translator.component.html",
  styleUrls: ["./translator.component.scss"],
})
export class TranslatorComponent implements OnInit {
  lang: string;
  constructor(private service: LangService) {}

  ngOnInit(): void {
    this.service.currentLang.subscribe(
      (val) => {
        this.lang = val;
      },
      (err) => {
        console.group(err);
      }
    );
  }
}
