import { Component, OnInit } from "@angular/core";
import { FormGroup, FormControl, Validators } from "@angular/forms";
import { Observable } from "rxjs";
import { MultilangueService } from "../service/multilangue.service";
import { ProjetService } from "../service/projet.service";

export class Multilangue {
  page: string;
  code: string;
  libelle: string;
  langue: string;
}
@Component({
  selector: "app-multilangue",
  templateUrl: "./multilangue.component.html",
  styleUrls: ["./multilangue.component.scss"],
})
export class MultilangueComponent implements OnInit {
  multilangueForm = new FormGroup({
    page: new FormControl("", Validators.required),
    code: new FormControl("", Validators.required),
    libelle: new FormControl("", Validators.required),
    langue: new FormControl("", Validators.required),
  });

  langues$: Observable<Multilangue>;
  constructor(private database: MultilangueService) {}

  ngOnInit(): void {
    this.langues$ = this.database.getAll();
  }

  onSubmit() {
    const multilangue: Multilangue = this.multilangueForm.getRawValue();

    this.database.create(multilangue);
    this.multilangueForm.reset();
  }
}
