import { Component, Input, OnInit } from "@angular/core";
import { ActivatedRoute, NavigationExtras, Router } from "@angular/router";
import { of } from "rxjs";
import { Projet } from "../model/projet";
import { LangService } from "../service/lang.service";
import { ProjetService } from "../service/projet.service";
import {
  CategoryProject,
  CategoryProjetService,
} from "../services/category-projet.service";

export class ProjectState {
  idProject: string;
  idCategory: string;
  indexProjet: string;
  indexCategory: string;
}

@Component({
  selector: "app-card",
  templateUrl: "./card.component.html",
  styleUrls: ["./card.component.scss"],
})
export class CardComponent implements OnInit {
  @Input() projet$;
  @Input() projectIndex;
  @Input() catIndex;
  @Input() category;

  lang: string;

  projet: Projet;
  categories$;
  constructor(
    private router: Router,
    private service: LangService,
    private cpService: CategoryProjetService,
    private projetService: ProjetService
  ) {}

  ngOnInit(): void {
    if (this.projet$) {
      this.projetService
        .getById(this.projet$[0].id)
        .toPromise()
        .then((x) => {
          this.projet = x;
        });
    }
    this.service.currentLang.subscribe(
      (val) => {
        this.lang = val;
      },
      (err) => {
        console.group(err);
      }
    );
    this.categories$ = this.cpService.getAll();
  }

  goToProject(
    category: string,
    projectId: string,
    catIndex: string,
    projectIndex: string
  ) {
    const projectState = new ProjectState();
    projectState.idCategory = category;
    projectState.idProject = projectId;
    projectState.indexProjet = projectIndex;
    projectState.indexCategory = catIndex;

    const navigationExtras: NavigationExtras = {
      state: projectState,
    };
    this.router.navigate(["project"], navigationExtras);
  }
}
