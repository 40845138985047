import { Pipe, PipeTransform } from "@angular/core";

@Pipe({
  name: "troncateString",
})
export class TroncateStringPipe implements PipeTransform {
  transform(value: string, charNumber: number): string {
    if (!value) {
      return "";
    }
    if (value.length < charNumber) {
      return value;
    }
    return value.substring(0, charNumber) + "...";
  }
}
