import { Injectable } from "@angular/core";
import {
  AngularFirestore,
  AngularFirestoreCollection,
} from "@angular/fire/firestore";
import { Observable } from "rxjs";
import { map } from "rxjs/operators";
export class Category {
  id: string;
  libelle: string;
}
@Injectable({
  providedIn: "root",
})
export class CategoriesService {
  COLLECTION = "Categories";
  private collection: AngularFirestoreCollection<Category>;
  categories$: Observable<any>;

  constructor(private fire: AngularFirestore) {}

  getAll(): Observable<any> {
    this.collection = this.fire.collection(this.COLLECTION, (ref) =>
      ref.orderBy("libelle", "asc")
    );

    this.categories$ = this.collection.snapshotChanges().pipe(
      map((actions) => {
        return actions.map((a) => {
          const data = a.payload.doc.data() as Category;
          data.id = a.payload.doc.id;
          return data;
        });
      })
    );
    return this.categories$;
  }

  getLibelleBy(libelle: string): Observable<any> {
    this.collection = this.fire.collection(this.COLLECTION, (ref) =>
      ref.where("libelle", "==", libelle)
    );
    this.categories$ = this.collection.snapshotChanges().pipe(
      map((actions) => {
        return actions.map((a) => {
          const data = a.payload.doc.data() as Category;
          data.id = a.payload.doc.id;
          return data;
        });
      })
    );
    return this.categories$;
  }

  async getByCode(id: string): Promise<any> {
    this.collection = this.fire.collection(this.COLLECTION);
    return await Promise.resolve(
      this.collection
        .doc(id)
        .ref.get()
        .then((doc) => {
          const cat = doc.data() as Category;
          cat.id = id;
          return cat;
        })
        .catch((error) => {
          console.log("Error getting document:", error);
        })
    );
  }

  create(data: Category): any {
    return new Promise<any>((resolve, reject) => {
      this.fire
        .collection(this.COLLECTION)
        .add(data)
        .then(
          (res) => {},
          (err) => reject(err)
        );
    });
  }
}
