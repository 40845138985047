import { Component, EventEmitter, OnInit, Output } from "@angular/core";
import { LangService } from "src/app/service/lang.service";

@Component({
  selector: "app-sidebar",
  templateUrl: "./sidebar.component.html",
  styleUrls: ["./sidebar.component.scss"],
})
export class SidebarComponent implements OnInit {
  @Output() sidenavClose = new EventEmitter();
  lang: string;
  constructor(private service: LangService) {}

  ngOnInit(): void {
    this.service.currentLang.subscribe(
      (val) => {
        this.lang = val;
      },
      (err) => {
        console.group(err);
      }
    );
  }
  public onSidenavClose = () => {
    this.sidenavClose.emit();
  };
}
