import { Injectable } from "@angular/core";
import {
  AngularFirestoreCollection,
  AngularFirestore,
} from "@angular/fire/firestore";
import { Observable } from "rxjs";
import { map } from "rxjs/operators";
import { Projet } from "../model/projet";
import { Category } from "./categories.service";
export class CategoryProject {
  id: string;
  category: Category;
  projet: Projet[];
}
@Injectable({
  providedIn: "root",
})
export class CategoryProjetService {
  COLLECTION = "JoinCategoryProject";
  private collection: AngularFirestoreCollection<CategoryProject>;
  projets$: Observable<any>;

  constructor(private fire: AngularFirestore) {}

  getAll(): Observable<any> {
    this.collection = this.fire.collection(this.COLLECTION, (ref) =>
      ref.orderBy("category.libelle", "asc")
    );

    this.projets$ = this.collection.snapshotChanges().pipe(
      map((actions) => {
        return actions.map((a) => {
          const data = a.payload.doc.data() as CategoryProject;
          data.id = a.payload.doc.id;
          return data;
        });
      })
    );
    return this.projets$;
  }

  getByCode(id: string): Promise<any> {
    this.collection = this.fire.collection(this.COLLECTION);

    return this.collection
      .doc(id)
      .ref.get()
      .then((doc) => {
        const data = doc.data() as CategoryProject;
        data.id = doc.id;
        return data;
      })
      .catch((error) => {
        console.log("Error getting document:", error);
      });
  }

  getCategoryContainingProject(idProject: string): Observable<any> {
    this.collection = this.fire.collection(this.COLLECTION, (ref) =>
      ref.where("projet", "array-contains", idProject)
    );
    this.projets$ = this.collection.snapshotChanges().pipe(
      map((actions) => {
        return actions.map((a) => {
          const data = a.payload.doc.data() as CategoryProject;
          data.id = a.payload.doc.id;
          return data;
        });
      })
    );
    return this.projets$;
  }

  getCategoryContainingCategory(idCategory: string): Observable<any> {
    this.collection = this.fire.collection(this.COLLECTION, (ref) =>
      ref.where("category.id", "==", idCategory)
    );
    this.projets$ = this.collection.snapshotChanges().pipe(
      map((actions) => {
        return actions.map((a) => {
          const data = a.payload.doc.data() as CategoryProject;
          data.id = a.payload.doc.id;
          return data;
        });
      })
    );
    return this.projets$;
  }

  create(data: CategoryProject): any {
    return new Promise<any>((resolve, reject) => {
      this.fire
        .collection(this.COLLECTION)
        .add(Object.assign({}, data))
        .then(
          (res) => {},
          (err) => reject(err)
        );
    });
  }

  update(data: CategoryProject): any {
    this.fire
      .collection(this.COLLECTION)
      .doc(data.id)
      .set(Object.assign({}, data));
  }
}
