import { Pipe, PipeTransform } from "@angular/core";

@Pipe({
  name: "surface",
})
export class SurfacePipe implements PipeTransform {
  transform(value: string): string {
    return value || value.length > 0 ? value + " m²" : "";
  }
}
