import { Component, EventEmitter, OnInit, Output } from "@angular/core";
import { LangService } from "src/app/service/lang.service";
import { TranslatorComponent } from "src/app/shared/component/translator/translator.component";

@Component({
  selector: "app-toolbar",
  templateUrl: "./toolbar.component.html",
  styleUrls: ["./toolbar.component.scss"],
})
export class ToolbarComponent implements OnInit {
  @Output() public sidenavToggle = new EventEmitter();
  lang: string;

  constructor(private langService: LangService) {
    this.langService.currentLang.subscribe(
      (val) => {
        this.lang = val;
      },
      (err) => {
        console.group(err);
      }
    );
  }

  ngOnInit(): void {}

  public onToggleSidenav = () => {
    this.sidenavToggle.emit();
  };
}
