export const environment = {
  production: true,
  config: {
    apiKey: "AIzaSyASp7GzNSdisee3hyMb7iXBFP_bkZeJXfM",
    authDomain: "hr-architecture.firebaseapp.com",
    databaseURL: "https://hr-architecture.firebaseio.com",
    projectId: "hr-architecture",
    storageBucket: "hr-architecture.appspot.com",
    messagingSenderId: "130630129706",
    appId: "1:130630129706:web:08f10e91e380a05961cb53",
    measurementId: "G-8E8BQCBS38",
  },
};
