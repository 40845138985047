import { Injectable } from "@angular/core";
import {
  AngularFirestoreCollection,
  AngularFirestore,
} from "@angular/fire/firestore";
import { Observable, from } from "rxjs";
import { map } from "rxjs/operators";
import { Projet } from "../model/projet";
import { Multilangue } from "../multilangue/multilangue.component";

@Injectable({
  providedIn: "root",
})
export class MultilangueService {
  COLLECTION = "Translate";
  private collection: AngularFirestoreCollection<Multilangue>;
  projets$: Observable<any>;

  constructor(private fire: AngularFirestore) {}

  getAll(): Observable<any> {
    this.collection = this.fire.collection(this.COLLECTION, (ref) =>
      ref.orderBy("page", "asc").orderBy("langue", "asc")
    );

    this.projets$ = this.collection.snapshotChanges().pipe(
      map((actions) => {
        return actions.map((a) => {
          const data = a.payload.doc.data() as Multilangue;
          return { ...data };
        });
      })
    );
    return this.projets$;
  }

  getLibelleBy(page: string, code: string, langue: string): Observable<any> {
    this.collection = this.fire.collection(this.COLLECTION, (ref) =>
      ref
        .where("page", "==", page)
        .where("code", "==", code)
        .where("langue", "==", langue)
    );
    this.projets$ = this.collection.snapshotChanges().pipe(
      map((actions) => {
        return actions.map((a) => {
          const data = a.payload.doc.data() as Multilangue;
          const libelle = data.libelle as string;

          return libelle;
        });
      })
    );
    return this.projets$;
  }

  create(data: Multilangue): any {
    return new Promise<any>((resolve, reject) => {
      this.fire
        .collection(this.COLLECTION)
        .add(data)
        .then(
          (res) => {},
          (err) => reject(err)
        );
    });
  }
}
