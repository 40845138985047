import { OnChanges, SimpleChanges } from "@angular/core";
import { Component, HostListener, OnInit } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { LangService } from "../service/lang.service";

declare let Email: any;

@Component({
  selector: "app-contact",
  templateUrl: "./contact.component.html",
  styleUrls: ["./contact.component.scss"],
})
export class ContactComponent implements OnInit {
  contactForm: FormGroup;
  disabledSubmitButton = true;
  optionsSelect: Array<any>;
  messageSent = false;
  lang: string;
  @HostListener("input") oninput() {
    if (this.contactForm.valid) {
      this.disabledSubmitButton = false;
    }
  }

  constructor(private fb: FormBuilder, private langService: LangService) {}

  ngOnInit(): void {
    this.contactForm = this.fb.group({
      name: ["", Validators.required],
      subject: ["", Validators.required],
      email: ["", Validators.compose([Validators.required, Validators.email])],
      message: ["", Validators.required],
    });
    this.optionsSelect = [
      { value: "Feedback", label: "Feedback" },
      { value: "Report a bug", label: "Report a bug" },
      { value: "Feature request", label: "Feature request" },
      { value: "Other stuff", label: "Other stuff" },
    ];
    this.langService.currentLang.subscribe(
      (val) => {
        this.lang = val;
      },
      (err) => {
        console.group(err);
      }
    );
  }

  get f() {
    return this.contactForm.controls;
  }

  get name() {
    return this.contactForm.get("name").value;
  }
  get email() {
    return this.contactForm.get("email").value;
  }

  get message() {
    return this.contactForm.get("message").value;
  }

  get subject() {
    return this.contactForm.get("subject").value;
  }

  onSubmit() {
    this.messageSent = false;
    if (this.contactForm.invalid) {
      return;
    }
    Email.send({
      SecureToken: "e8750821-c75a-46df-9015-ceac90801009",
      To: ["h.redissi@hrarchitecture.studio, r.touibi@hrarchitecture.studio"],
      From: "support@hrarchitecture.studio",
      Subject: "[HR Architecture] Nouveau contact",
      Body: `<!DOCTYPE HTML PUBLIC "-//W3C//DTD XHTML 1.0 Transitional //EN" "http://www.w3.org/TR/xhtml1/DTD/xhtml1-transitional.dtd">
<html xmlns="http://www.w3.org/1999/xhtml" xmlns:v="urn:schemas-microsoft-com:vml" xmlns:o="urn:schemas-microsoft-com:office:office">
   <head>
      <!--[if gte mso 9]>
      <xml>
         <o:OfficeDocumentSettings>
            <o:AllowPNG/>
            <o:PixelsPerInch>96</o:PixelsPerInch>
         </o:OfficeDocumentSettings>
      </xml>
      <![endif]-->
      <meta http-equiv="Content-Type" content="text/html; charset=UTF-8">
      <meta name="viewport" content="width=device-width, initial-scale=1.0">
      <meta name="x-apple-disable-message-reformatting">
      <!--[if !mso]><!-->
      <meta http-equiv="X-UA-Compatible" content="IE=edge">
      <!--<![endif]-->
      <title></title>
      <style type="text/css">
         a { color: #0000ee; text-decoration: underline; }
         @media only screen and (min-width: 570px) {
         .u-row {
         width: 550px !important;
         }
         .u-row .u-col {
         vertical-align: top;
         }
         .u-row .u-col-100 {
         width: 550px !important;
         }
         }
         @media (max-width: 570px) {
         .u-row-container {
         max-width: 100% !important;
         padding-left: 0px !important;
         padding-right: 0px !important;
         }
         .u-row .u-col {
         min-width: 320px !important;
         max-width: 100% !important;
         display: block !important;
         }
         .u-row {
         width: calc(100% - 40px) !important;
         }
         .u-col {
         width: 100% !important;
         }
         .u-col > div {
         margin: 0 auto;
         }
         }
         body {
         margin: 0;
         padding: 0;
         }
         table,
         tr,
         td {
         vertical-align: top;
         border-collapse: collapse;
         }
         p {
         margin: 0;
         }
         .ie-container table,
         .mso-container table {
         table-layout: fixed;
         }
         * {
         line-height: inherit;
         }
         a[x-apple-data-detectors='true'] {
         color: inherit !important;
         text-decoration: none !important;
         }
      </style>
      <!--[if !mso]><!-->
      <link href="https://fonts.googleapis.com/css?family=Lato:400,700&display=swap" rel="stylesheet" type="text/css">
      <!--<![endif]-->
   </head>
   <body class="clean-body" style="margin: 0;padding: 0;-webkit-text-size-adjust: 100%;background-color: #f7f7f7">
      <!--[if IE]>
      <div class="ie-container">
         <![endif]-->
         <!--[if mso]>
         <div class="mso-container">
            <![endif]-->
            <table style="border-collapse: collapse;table-layout: fixed;border-spacing: 0;mso-table-lspace: 0pt;mso-table-rspace: 0pt;vertical-align: top;min-width: 320px;Margin: 0 auto;background-color: #f7f7f7;width:100%" cellpadding="0" cellspacing="0">
               <tbody>
                  <tr style="vertical-align: top">
                     <td style="word-break: break-word;border-collapse: collapse !important;vertical-align: top">
                        <!--[if (mso)|(IE)]>
                        <table width="100%" cellpadding="0" cellspacing="0" border="0">
                           <tr>
                              <td align="center" style="background-color: #f7f7f7;">
                                 <![endif]-->
                                 
                                 
                                 <div class="u-row-container" style="padding: 0px;background-color: transparent">
                                    <div class="u-row" style="Margin: 0 auto;min-width: 320px;max-width: 550px;overflow-wrap: break-word;word-wrap: break-word;word-break: break-word;background-color: #ffffff;">
                                       <div style="border-collapse: collapse;display: table;width: 100%;background-color: transparent;">
                                          <!--[if (mso)|(IE)]>
                                          <table width="100%" cellpadding="0" cellspacing="0" border="0">
                                             <tr>
                                                <td style="padding: 0px;background-color: transparent;" align="center">
                                                   <table cellpadding="0" cellspacing="0" border="0" style="width:550px;">
                                                      <tr style="background-color: #ffffff;">
                                                         <![endif]-->
                                                         <!--[if (mso)|(IE)]>
                                                         <td align="center" width="550" style="width: 550px;padding: 0px;border-top: 0px solid transparent;border-left: 0px solid transparent;border-right: 0px solid transparent;border-bottom: 0px solid transparent;" valign="top">
                                                            <![endif]-->
                                                            <div class="u-col u-col-100" style="max-width: 320px;min-width: 550px;display: table-cell;vertical-align: top;">
                                                               <div style="width: 100% !important;">
                                                                  <!--[if (!mso)&(!IE)]><!-->
                                                                  <div style="padding: 0px;border-top: 0px solid transparent;border-left: 0px solid transparent;border-right: 0px solid transparent;border-bottom: 0px solid transparent;">
                                                                     <!--<![endif]-->
                                                                     <table style="font-family:arial,helvetica,sans-serif;" role="presentation" cellpadding="0" cellspacing="0" width="100%" border="0">
                                                                        <tbody>
                                                                           <tr>
                                                                              <td style="overflow-wrap:break-word;word-break:break-word;padding:10px 15px;font-family:arial,helvetica,sans-serif;" align="left">
                                                                                 <div style="color: #000000; line-height: 140%; text-align: left; word-wrap: break-word;">
                                                                                    <p style="font-size: 14px; line-height: 140%;"><span style="font-size: 16px; line-height: 22.4px;"><strong>Bonjour,</strong></span></p>
                                                                                    <p style="font-size: 14px; line-height: 140%;"><br /><span style="font-size: 16px; line-height: 22.4px;">Vous avez un nouveau contact via le site HRArchitecture de la part de Mr/Mme <span style="text-decoration: underline; font-size: 14px; line-height: 19.6px; color: #1B4F72">${this.name}</span>&nbsp; :&nbsp;</p>
                                                                                    <p style="font-size: 14px; line-height: 140%;"><br /><strong>Object du message :</strong> <i>${this.subject}.</i></p>
                                                                                    <p style="font-size: 14px; line-height: 140%;"><br /><strong>Le message étant le suivant :</strong> </i></p>
                                                                                    <p style="font-size: 14px; line-height: 140%;"><br /><i>${this.message}.</i></p>
                                                                                    <p style="font-size: 14px; line-height: 140%;">&nbsp;</p>
                                                                                    <p style="font-size: 14px; line-height: 140%;"> <b>~Fin du Message.~</b></p>
                                                                                    <p style="font-size: 14px; line-height: 140%;">&nbsp;</p>
                                                                                    <p style="font-size: 14px; line-height: 140%;">L'adresse de contact est la suivante <span style="color: #ff0000; font-size: 14px; line-height: 19.6px;"><a style="color: #ff0000;" href="mailto:${this.email}" target="_blank" rel="noopener">${this.email}</a></span></p>
                                                                                    <p style="font-size: 14px; line-height: 140%;">&nbsp;</p>
                                                                                 </div>
                                                                              </td>
                                                                           </tr>
                                                                        </tbody>
                                                                     </table>
                                                                     <!--[if (!mso)&(!IE)]><!-->
                                                                  </div>
                                                                  <!--<![endif]-->
                                                               </div>
                                                            </div>
                                                            <!--[if (mso)|(IE)]>
                                                         </td>
                                                         <![endif]-->
                                                         <!--[if (mso)|(IE)]>
                                                      </tr>
                                                   </table>
                                                </td>
                                             </tr>
                                          </table>
                                          <![endif]-->
                                       </div>
                                    </div>
                                 </div>
                                 <div class="u-row-container" style="padding: 0px;background-color: transparent">
                                    <div class="u-row" style="Margin: 0 auto;min-width: 320px;max-width: 550px;overflow-wrap: break-word;word-wrap: break-word;word-break: break-word;background-color: #ffffff;">
                                       <div style="border-collapse: collapse;display: table;width: 100%;background-color: transparent;">
                                          <!--[if (mso)|(IE)]>
                                          <table width="100%" cellpadding="0" cellspacing="0" border="0">
                                             <tr>
                                                <td style="padding: 0px;background-color: transparent;" align="center">
                                                   <table cellpadding="0" cellspacing="0" border="0" style="width:550px;">
                                                      <tr style="background-color: #ffffff;">
                                                         <![endif]-->
                                                         <!--[if (mso)|(IE)]>
                                                         <td align="center" width="550" style="width: 550px;padding: 0px;border-top: 0px solid transparent;border-left: 0px solid transparent;border-right: 0px solid transparent;border-bottom: 0px solid transparent;" valign="top">
                                                            <![endif]-->
                                                            <div class="u-col u-col-100" style="max-width: 320px;min-width: 550px;display: table-cell;vertical-align: top;">
                                                               <div style="width: 100% !important;">
                                                                  <!--[if (!mso)&(!IE)]><!-->
                                                                  <div style="padding: 0px;border-top: 0px solid transparent;border-left: 0px solid transparent;border-right: 0px solid transparent;border-bottom: 0px solid transparent;">
                                                                     <!--<![endif]-->
                                                                     <table style="font-family:arial,helvetica,sans-serif;" role="presentation" cellpadding="0" cellspacing="0" width="100%" border="0">
                                                                        <tbody>
                                                                           <tr>
                                                                              <td style="overflow-wrap:break-word;word-break:break-word;padding:10px;font-family:arial,helvetica,sans-serif;" align="left">
                                                                                 <table height="0px" align="center" border="0" cellpadding="0" cellspacing="0" width="100%" style="border-collapse: collapse;table-layout: fixed;border-spacing: 0;mso-table-lspace: 0pt;mso-table-rspace: 0pt;vertical-align: top;border-top: 1px solid #f1f1f1;-ms-text-size-adjust: 100%;-webkit-text-size-adjust: 100%">
                                                                                    <tbody>
                                                                                       <tr style="vertical-align: top">
                                                                                          <td style="word-break: break-word;border-collapse: collapse !important;vertical-align: top;font-size: 0px;line-height: 0px;mso-line-height-rule: exactly;-ms-text-size-adjust: 100%;-webkit-text-size-adjust: 100%">
                                                                                             <span>&#160;</span>
                                                                                            <p style="font-size: 14px; line-height: 140%;"><strong>HRArchitecture</strong><br />&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; </p>

                                                                                          </td>
                                                                                       </tr>
                                                                                    </tbody>
                                                                                 </table>
                                                                              </td>
                                                                           </tr>
                                                                        </tbody>
                                                                     </table>
                                                                     <!--[if (!mso)&(!IE)]><!-->
                                                                  </div>
                                                                  <!--<![endif]-->
                                                               </div>
                                                            </div>
                                                            <!--[if (mso)|(IE)]>
                                                         </td>
                                                         <![endif]-->
                                                         <!--[if (mso)|(IE)]>
                                                      </tr>
                                                   </table>
                                                </td>
                                             </tr>
                                          </table>
                                          <![endif]-->
                                       </div>
                                    </div>
                                 </div>
                                 <!--[if (mso)|(IE)]>
                              </td>
                           </tr>
                        </table>
                        <![endif]-->
                     </td>
                  </tr>
               </tbody>
            </table>
            <!--[if mso]>
         </div>
         <![endif]-->
         <!--[if IE]>
      </div>
      <![endif]-->
   </body>
</html>`,
    }).then((message) => {
      this.messageSent = true;
      this.contactForm.reset();
    });
  }
}
