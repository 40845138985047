import { Component, OnInit } from "@angular/core";
import { FormGroup, FormControl, Validators } from "@angular/forms";
import { Observable } from "rxjs";
import { Projet } from "../model/projet";
import { ProjetService } from "../service/projet.service";
import { CategoriesService, Category } from "../services/categories.service";
import {
  CategoryProject,
  CategoryProjetService,
} from "../services/category-projet.service";

@Component({
  selector: "app-categories",
  templateUrl: "./categories.component.html",
  styleUrls: ["./categories.component.scss"],
})
export class CategoriesComponent implements OnInit {
  categoryForm = new FormGroup({
    libelle: new FormControl("", Validators.required),
  });

  joinForm = new FormGroup({
    projet: new FormControl("", Validators.required),
    category: new FormControl("", Validators.required),
  });
  selectedCategory: Category;

  categories$: Category[];
  projets$: Projet[];
  join$: CategoryProject[];

  isEdit = false;
  editedDocId: string | undefined;

  constructor(
    private projetsService: ProjetService,
    private categoryService: CategoriesService,
    private joinService: CategoryProjetService
  ) {}

  ngOnInit(): void {
    this.projetsService.getAll().subscribe({
      next: (result: any) => {
        this.projets$ = result;
      },
      error: (err: any) => {},
      complete: () => {},
    });
    this.categoryService.getAll().subscribe((item) => {
      this.categories$ = item;
    });

    this.joinService.getAll().subscribe((item) => {
      this.join$ = item;
    });
  }

  onSubmitCategory() {
    const category: Category = this.categoryForm.getRawValue();

    this.categoryService.create(category);
    this.categoryForm.reset();
  }

  onSubmitJoin() {
    const join: {
      category: Category;
      projet: Projet[];
    } = this.joinForm.getRawValue();
    if (!this.isEdit) {
      const categoryProjet = new CategoryProject();
      categoryProjet.category = join.category;
      categoryProjet.projet = join.projet;
      this.joinService.create(categoryProjet);
    } else {
      const categoryProjet = new CategoryProject();
      categoryProjet.category = join.category;
      categoryProjet.projet = join.projet;
      categoryProjet.id = this.editedDocId;
      this.joinService.update(categoryProjet);
    }
    this.isEdit = false;
    this.joinForm.reset();
  }

  editCategorieProjet(id: string) {
    this.isEdit = true;
    this.joinService.getByCode(id).then((x) => {
      console.log(x);
      this.editedDocId = x.id;
      this.joinForm.patchValue(x);
    });
  }
}
