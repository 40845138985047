import { Pipe, PipeTransform } from "@angular/core";
import { CategoryProject } from "../services/category-projet.service";

@Pipe({
  name: "categoryId",
})
export class CategoryIdPipe implements PipeTransform {
  transform(categories$: CategoryProject[], idProjet): string {
    if (!categories$ || !idProjet) {
      return "";
    }
    let category;
    categories$.forEach((x) => {
      if (x.projet.find((project) => project === idProjet)) {
        category = x;
      }
    });
    if (!category) {
      return "";
    }
    return category.category.id;
  }
}
