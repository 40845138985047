import { Injectable } from "@angular/core";
import { BehaviorSubject } from "rxjs";

@Injectable({
  providedIn: "root",
})
export class LangService {
  private lang = new BehaviorSubject("fr");
  currentLang = this.lang.asObservable();

  constructor() {}

  emitLang(lang: string) {
    this.lang.next(lang);
  }
}
