import { AfterViewInit, Component, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { Observable } from "rxjs";
import { ProjetService } from "../service/projet.service";
import {
  CategoryProject,
  CategoryProjetService,
} from "../services/category-projet.service";

@Component({
  selector: "app-project",
  templateUrl: "./project.component.html",
  styleUrls: ["./project.component.scss"],
})
export class ProjectComponent implements OnInit, AfterViewInit {
  projet$: Observable<any>;
  categories$: Observable<CategoryProject[]>;
  selectedProjetIndex;
  selectedTabCategory;
  selectedIndex = 0;
  appstate$;
  indexObs: Observable<number>;

  constructor(
    private projetService: ProjetService,
    private router: Router,
    private categoriesService: CategoryProjetService // private change: ChangeDetectorRef
  ) {
    const currentState = this.router.getCurrentNavigation();
    this.appstate$ = currentState.extras.state;
    this.selectedTabCategory = this.appstate$
      ? this.appstate$.indexCategory
      : 0;
    this.selectedProjetIndex = this.appstate$ ? this.appstate$.indexProjet : 0;
  }
  ngAfterViewInit(): void {}

  ngOnInit() {
    this.projet$ = this.projetService.getAll();
    this.categories$ = this.categoriesService.getAll();
  }
}
