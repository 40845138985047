import {
  AfterViewInit,
  Component,
  Input,
  OnInit,
  ViewEncapsulation,
} from "@angular/core";
import { Observable } from "rxjs";
import { CategoriesService } from "../services/categories.service";
import { CategoryProjetService } from "../services/category-projet.service";

@Component({
  selector: "app-project-category-tab",
  templateUrl: "./project-category-tab.component.html",
  styleUrls: ["./project-category-tab.component.scss"],
  encapsulation: ViewEncapsulation.None,
})
export class ProjectCategoryTabComponent implements OnInit, AfterViewInit {
  categories$: Observable<any>;
  @Input() selectedCategory = 0;
  @Input() selectedProject = 0;
  selectedIndex: number;

  constructor(private categories: CategoryProjetService) {}

  ngAfterViewInit(): void {}

  ngOnInit(): void {
    this.categories.getAll().subscribe((x) => {
      this.categories$ = x;
      window.setTimeout(() => {
        this.selectedIndex = this.selectedCategory;
      });
      // this.tabGroup.selectedIndex = this.selectedCategory;
    });
  }
}
