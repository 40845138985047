import { Component, Input, OnInit } from "@angular/core";
import { FormControl, FormGroup, Validators } from "@angular/forms";
import { Observable } from "rxjs";
import { Projet } from "../model/projet";
import { ProjetService } from "../service/projet.service";

@Component({
  selector: "app-uploader",
  templateUrl: "./uploader.component.html",
  styleUrls: ["./uploader.component.scss"],
})
export class UploaderComponent implements OnInit {
  isHovering: boolean;
  projets$: Observable<any>;

  files: Array<File> = new Array();

  uploadedFiles = new Array<string>();
  isEdit = false;

  uploadForm = new FormGroup({
    titre: new FormControl("", Validators.required),
    ville: new FormControl("", Validators.required),
    annee: new FormControl(""),
    phase: new FormControl("", Validators.required),
    surface: new FormControl(""),
    terrain: new FormControl(""),
  });
  editedDocId: any;
  constructor(private database: ProjetService) {}

  ngOnInit(): void {
    this.projets$ = this.database.getAll();
  }

  toggleHover(event: boolean) {
    this.isHovering = event;
  }

  onDrop(files: FileList) {
    for (let i = 0; i < files.length; i++) {
      const file = files.item(i);
      // file.name.replace(" ", "_").replace("(", "_").replace(")", "_");
      this.files.push(file);
    }
  }
  get f() {
    return this.uploadForm.controls;
  }

  get titre() {
    return this.uploadForm.get("titre");
  }
  get ville() {
    return this.uploadForm.get("ville");
  }
  get annee() {
    return this.uploadForm.get("annee");
  }

  get phase() {
    return this.uploadForm.get("phase");
  }
  get surface() {
    return this.uploadForm.get("surface");
  }
  get terrain() {
    return this.uploadForm.get("terrain");
  }

  uploadFormValid() {
    return this.uploadForm.valid;
  }

  setFileUrl(fileUrl: any) {
    this.uploadedFiles.push(fileUrl);
  }

  onSubmit() {
    const projet: Projet = this.uploadForm.getRawValue();
    projet.images = this.uploadedFiles;
    if (!this.isEdit) {
      this.database.create(projet);
    } else {
      projet.id = this.editedDocId;
      this.database.update(projet);
    }
    this.uploadForm.reset();
    this.uploadedFiles = [];
    this.files = [];
  }

  editProjet(id: string) {
    this.database
      .getById(id)
      .toPromise()
      .then((x) => {
        this.editedDocId = x.id;
        this.uploadForm.patchValue(x);
      });
  }
}
