import { Pipe, PipeTransform } from "@angular/core";

@Pipe({
  name: "phaseAnnee",
})
export class PhaseAnneePipe implements PipeTransform {
  transform(value: string, args: string): string {
    let annee = args ? " en " + args : "";
    return value + annee;
  }
}
