import { Component, Input, OnInit } from "@angular/core";
import { Projet } from "../model/projet";
import { LangService } from "../service/lang.service";

@Component({
  selector: "app-flat-caroussel",
  templateUrl: "./flat-caroussel.component.html",
  styleUrls: ["./flat-caroussel.component.scss"],
})
export class FlatCarousselComponent implements OnInit {
  @Input()
  projet$: Projet;

  lang: string;
  constructor(private service: LangService) {}

  ngOnInit(): void {
    this.service.currentLang.subscribe(
      (val) => {
        this.lang = val;
      },
      (err) => {
        console.group(err);
      }
    );
  }
}
