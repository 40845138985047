import {
  AfterViewInit,
  Component,
  Input,
  OnInit,
  ViewChild,
} from "@angular/core";
import { MatTabGroup } from "@angular/material/tabs";
import { Observable } from "rxjs";
import { Projet } from "../model/projet";
import { ProjetService } from "../service/projet.service";
import {
  CategoryProject,
  CategoryProjetService,
} from "../services/category-projet.service";

@Component({
  selector: "app-project-tab",
  templateUrl: "./project-tab.component.html",
  styleUrls: ["./project-tab.component.scss"],
})
export class ProjectTabComponent implements OnInit, AfterViewInit {
  selectedIndex;

  tabs: Projet[] = [];

  @Input() selectedTab = 0;
  @Input() category;
  projet$: string[];

  constructor(
    private projetService: ProjetService,
    private categoryProjetService: CategoryProjetService
  ) {}

  ngAfterViewInit(): void {}

  ngOnInit(): void {
    this.categoryProjetService
      .getCategoryContainingCategory(this.category)
      .subscribe((res: CategoryProject) => {
        if (res[0]) {
          res[0].projet.forEach((element) => {
            this.tabs = [];
            this.projetService
              .getById(element)
              .toPromise()
              .then((projet) => {
                if (
                  !(
                    this.tabs.filter((e) => e.titre === projet.titre).length > 0
                  )
                )
                  this.tabs.push(projet);
                window.setTimeout(() => {
                  this.selectedIndex = this.selectedTab;
                });
              });
          });
        }
      });
  }
}
