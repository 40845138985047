import { Pipe, PipeTransform } from "@angular/core";
import { CategoryProject } from "../services/category-projet.service";

@Pipe({
  name: "categoryIndex",
})
export class CategoryIndexPipe implements PipeTransform {
  transform(categories$: any[], idProjet): number {
    if (!categories$ || !idProjet) {
      return 0;
    }
    let category;
    categories$.forEach((x) => {
      if (x.projet.find((project) => project === idProjet)) {
        category = x;
      }
    });
    if (!category) {
      return 0;
    }
    return categories$.findIndex((cat) => cat === category);
  }
}
