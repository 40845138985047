import { Pipe, PipeTransform } from "@angular/core";
import { CategoryProject } from "../services/category-projet.service";

@Pipe({
  name: "projectIndex",
})
export class ProjectIndexPipe implements PipeTransform {
  transform(categories$: CategoryProject[], idProjet): number {
    if (!categories$ || !idProjet) {
      return 0;
    }
    let category;
    categories$.forEach((x) => {
      if (x.projet.find((project) => project === idProjet)) {
        category = x;
      }
    });
    if (!category) {
      return 0;
    }
    return category.projet.findIndex((projet) => projet === idProjet);
  }
}
