import { Component, Input, OnInit } from "@angular/core";
import { trigger, transition, animate, style } from "@angular/animations";
import { Projet } from "../model/projet";
import { NgbCarouselConfig } from "@ng-bootstrap/ng-bootstrap";

@Component({
  selector: "app-caroussel",
  templateUrl: "caroussel.component.html",
  styleUrls: ["caroussel.component.scss"],
})
export class CarousselComponent implements OnInit {
  @Input()
  projet$: Projet;

  constructor(config: NgbCarouselConfig) {
    // customize default values of carousels used by this component tree
    config.interval = 5000;
    config.keyboard = true;
    config.pauseOnHover = true;
  }

  ngOnInit() {}
}
